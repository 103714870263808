<div class="admin">
    <div class="admin__box">
      <img src="./../../assets/icon/ips.jpeg" class="admin__img" alt="">
      <form class="admin__form" >
        <mat-form-field class="admin__input">
          <mat-label>Correo</mat-label>
          <input matInput placeholder="Correo">
        </mat-form-field>
        <button mat-stroked-button color="primary" type="submit">Enviar</button>
      </form>
    </div>
  </div>
  