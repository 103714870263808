<!--Navbar-->
<mat-toolbar>
	<mat-icon (click)="drawer.toggle()" class="burger app-cursor">menu</mat-icon>
	<img alt="Logo" src="./../../assets/icon/ips.jpeg" class="logo-app"  style="margin-left: 15px;"/>
	<span class="example-spacer"></span>
	<label class="admin-text app-m-name"> Administrador</label>
	<mat-icon [matMenuTriggerFor]="menu" class="burger app-cursor">keyboard_arrow_down</mat-icon>
	<mat-menu #menu="matMenu">
		<button mat-menu-item (click)="logout()">
			<img src="../../../assets/icons/logout_baseline.svg" alt="" class="app-m-left">
			<span class="user-text app-m-left-15">Cerrar sesión</span>
		</button>
	</mat-menu>
	
</mat-toolbar>

<mat-drawer-container class="example-container" autosize>
	<mat-drawer #drawer class="example-sidenav" mode="side">
        <p class="user-text" routerLink="/list">Lista</p>
		<p class="user-text" routerLink="/schedule">Horario</p>
		<p class="user-text"routerLink="/home">Cargar Archivo</p>
		<p class="user-text"routerLink="/create/user/doctor">Crear usuario doctor</p>
	</mat-drawer>
</mat-drawer-container>

<div class="home">
    <div class="home__box">
        <div class="home__title">
            Cargar CSV con los datos de los usuario.
		</div>
		
		<form [formGroup]="excelForm" (ngSubmit)="onSubmit()">
			<div class="home__input">
				<input type="file" (change)="onFileSelect($event)" formControlName="file">
			</div>
			<button type="submit" class="btn"> <span style="font-size: 20px;">Cargar</span> </button>
		</form>
	</div>
</div>
